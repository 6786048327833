import { CallApiResponseAction } from 'store/middleware/api';
import { PurchaseElementData } from 'types/Api/Reservation';
import { State } from 'types/Store/CheckInAddonStore';

import { ReservationInventoryItemDto } from '@ac/library-api';

import types from './types';

const initialState: State = {
  isFetching: false,
  confirmedNewPurchaseOrders: [],
  confirmedNewInventoryItems: [],
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS: {
      const { data } = action.response;
      const {
        payload: {
          purchaseElementsResponse,
          previousPreAddedElements,
          orderdItemsIds,
          preAddedInventoryItems,
        },
      } = action;
      const { results: purchaseElements } = purchaseElementsResponse;
      const { results: inventoryItems } = data._embedded.inventoryItems;

      const confirmedNewPurchaseOrders = purchaseElements.filter(
        ({ id, purchaseElementId }: PurchaseElementData) =>
          orderdItemsIds.includes(purchaseElementId) &&
          !previousPreAddedElements.find(
            (item: PurchaseElementData) => item.id === id
          )
      );

      const confirmedNewInventoryItems = inventoryItems.filter(
        ({ boundedItemId, inventoryItemId }: ReservationInventoryItemDto) =>
          !preAddedInventoryItems.find(
            (item: ReservationInventoryItemDto) =>
              boundedItemId === item.boundedItemId &&
              inventoryItemId === item.inventoryItemId
          )
      );

      return {
        ...state,
        confirmedNewPurchaseOrders,
        confirmedNewInventoryItems,
        isFetching: false,
      };
    }
    case types.FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS: {
      return {
        ...state,
        confirmedNewPurchaseOrders: [],
        confirmedNewInventoryItems: [],
      };
    }

    default:
      return state;
  }
};
